/*#region paddingTop*/

.padding-top-10 {
    padding-top: 10px;
}

.padding-top-15 {
    padding-top: 15px;
}

.padding-top-20 {
    padding-top: 20px;
}

.padding-top-25 {
    padding-top: 25px;
}

.padding-top-35 {
    padding-top: 35px;
}

.padding-top-40 {
    padding-top: 40px;
}

.padding-top-50 {
    padding-top: 50px;
}

.padding-top-60 {
    padding-top: 60px;
}

.padding-top-80 {
    padding-top: 80px;
}

.padding-top-100 {
    padding-top: 100px;
}

/*#endregion paddingTop*/

/*#region paddingBottom*/
.padding-bottom-10 {
    padding-bottom: 10px;
}

.padding-bottom-15 {
    padding-bottom: 15px;
}

.padding-bottom-20 {
    padding-bottom: 20px;
}

.padding-bottom-25 {
    padding-bottom: 25px;
}

.padding-bottom-30 {
    padding-bottom: 30px;
}

.padding-bottom-35 {
    padding-bottom: 35px;
}

.padding-bottom-40 {
    padding-bottom: 40px;
}

.padding-bottom-50 {
    padding-bottom: 50px;
}

.padding-bottom-60 {
    padding-bottom: 60px;
}

.padding-bottom-80 {
    padding-bottom: 80px;
}

.padding-bottom-100 {
    padding-bottom: 100px;
}
/*#endregion paddingBottom*/

/*#region paddingY*/

.padding-y-10 {
    padding-top: 10px;
    padding-bottom: 10px;
}

.padding-y-15 {
    padding-top: 15px;
    padding-bottom: 15px;
}

.padding-y-20 {
    padding-top: 20px;
    padding-bottom: 20px;
}

.padding-y-25 {
    padding-top: 25px;
    padding-bottom: 25px;
}

.padding-y-30 {
	padding-top: 30px;
	padding-bottom: 30px;
}

.padding-y-35 {
    padding-top: 35px;
    padding-bottom: 35px;
}

.padding-y-40 {
    padding-top: 40px;
    padding-bottom: 40px;
}

.padding-y-50 {
    padding-top: 50px;
    padding-bottom: 50px;
}

.padding-y-60 {
    padding-top: 60px;
    padding-bottom: 60px;
}

.padding-y-80 {
    padding-top: 80px;
    padding-bottom: 80px;
}

.padding-y-100 {
    padding-top: 100px;
    padding-bottom: 100px;
}

/*#endregion paddingY*/

/*#region paddingX*/

.padding-x-10p {
    padding: 0 10%;
}

.padding-x-10 {
    padding: 0 10px;
}

.padding-x-15 {
    padding: 0 15px;
}

.padding-x-20 {
    padding: 0 20px;
}



/*#endregion paddingX*/

/*#region marginTop*/

.margin-top-0 {
    margin-top: 0;
}

.margin-top-10 {
    margin-top: 10px;
}

.margin-top-15 {
    margin-top: 15px;
}

.margin-top-20 {
    margin-top: 20px;
}


.margin-top-25 {
    margin-top: 25px;
}

.margin-top-30 {
    margin-top: 30px;
}

.margin-top-35 {
    margin-top: 35px;
}

.margin-top-40 {
    margin-top: 40px;
}

.margin-top-50 {
    margin-top: 50px;
}

.margin-top-60 {
    margin-top: 60px;
}

.margin-top-80 {
    margin-top: 80px;
}

.margin-top-100 {
    margin-top: 100px;
}

/*#endregion marginTop*/

/*#region marginBottom*/
.margin-bottom-10 {
    margin-bottom: 10px;
}

.margin-bottom-15 {
    margin-bottom: 15px;
}

.margin-bottom-20 {
    margin-bottom: 20px;
}

.margin-bottom-25 {
    margin-bottom: 25px;
}
.margin-bottom-30 {
    margin-bottom: 30px;
}

.margin-bottom-35 {
    margin-bottom: 35px;
}

.margin-bottom-40 {
    margin-bottom: 40px;
}

.margin-bottom-50 {
    margin-bottom: 50px;
}

.margin-bottom-60 {
    margin-bottom: 60px;
}

.margin-bottom-80 {
    margin-bottom: 80px;
}

.margin-bottom-100 {
    margin-bottom: 100px;
}
/*#endregion marginBottom*/

/*#region marginY*/

.margin-y-10 {
    margin-top: 10px;
    margin-bottom: 10px;
}

.margin-y-15 {
    margin-top: 15px;
    margin-bottom: 15px;
}

.margin-y-20 {
    margin-top: 20px;
    margin-bottom: 20px;
}

.margin-y-25 {
    margin-top: 25px;
    margin-bottom: 25px;
}

.margin-y-30 {
    margin-top: 30px;
    margin-bottom: 30px;
}


.margin-y-35 {
    margin-top: 35px;
    margin-bottom: 35px;
}

.margin-y-40 {
    margin-top: 40px;
    margin-bottom: 40px;
}

.margin-y-50 {
    margin-top: 50px;
    margin-bottom: 50px;
}

.margin-y-60 {
    margin-top: 60px;
    margin-bottom: 60px;
}

.margin-y-80 {
    margin-top: 80px;
    margin-bottom: 80px;
}

.margin-y-100 {
    margin-top: 100px;
    margin-bottom: 100px;
}

/*#endregion marginY*/

.white {
    color: #FFF;
}

.black {
    color: #000;
}

.container-bg-white {
    background-color: #fff;
}

.uppercase {
    text-transform: uppercase;
}

.relative {
    position: relative;
}

.display-inline-block {
    display: inline-block;
}

.display-block {
    display: block;
}

img.HoverBounce,
.HoverBounce img {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    -webkit-transition: -webkit-transform 0.5s ease-in-out;
    transition: -webkit-transform 0.5s ease-in-out;
    transition: transform 0.5s ease-in-out;
    transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
    display: block;
    overflow: hidden;
}

    img.HoverBounce :hover,
    .HoverBounce img :hover {
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
        -webkit-transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
                transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
        z-index: 100;
    }

a.HoverBounce {
    display: block;
}

    a.HoverBounce img {
        -webkit-transform: translateZ(0);
                transform: translateZ(0);
        -webkit-backface-visibility: hidden;
                backface-visibility: hidden;
        -webkit-transition: -webkit-transform 0.5s ease-in-out;
        transition: -webkit-transform 0.5s ease-in-out;
        transition: transform 0.5s ease-in-out;
        transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
        display: block;
        overflow: hidden;
    }

    a.HoverBounce :hover {
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
        -webkit-transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
                transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
        z-index: 100;
    }

/*#region fonts*/
.BodyFont {
  font-family: 'Quicksand', sans-serif;
  font-weight: 700;
}
.Quicksand {
  font-family: 'Quicksand', sans-serif;
}
.FredokaOne {
  font-family: 'Fredoka One', cursive;
}
html {
  font-size: 16px;
}
body {
  font-family: 'Quicksand', sans-serif;
  font-weight: 700;
}
.textContent {
  color: #737373;
  font-size: 1rem;
}
@media (max-width: 400px) {
  .textContent a {
    word-break: break-word;
  }
}
@media (max-width: 767px) {
  .textContent {
    text-align: center;
  }
}
.textContent h1 {
  margin-top: 0;
  font-family: 'Fredoka One', cursive;
  color: #A7ADBB;
}
.textContent p {
  margin-bottom: 20px;
}
.textContent a {
  position: relative;
  padding: 0 3px;
  margin: 0 1px;
  display: inline-block;
  color: #5E6472;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}
.textContent a:hover {
  color: #5E6472;
}
.textContent a:hover:before {
  -webkit-transform: scaleY(1);
          transform: scaleY(1);
}
.textContent a:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #5E6472;
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
  z-index: -1;
  -webkit-transform-origin: center bottom;
          transform-origin: center bottom;
  -webkit-transform: scaleY(0.1);
          transform: scaleY(0.1);
}
h2,
h3,
h4 {
  font-family: 'Fredoka One', cursive;
}
/*#endregion fonts*/
form {
  position: relative;
  max-width: 100vw;
}
.outer-wrapper {
  max-width: 100vw;
  overflow-x: hidden;
  position: relative;
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  background: #AEEEED;
}
.outer-wrapper--alt .main-content {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}
@media (min-width: 768px) {
  .outer-wrapper--home .main-content {
    margin-top: -85px;
    padding-top: 70px;
  }
}
.container {
  position: relative;
  width: 100%;
}
@media (min-width: 1330px) {
  .container {
    width: 1300px;
  }
}
/*#region topLinksMobile*/
#TopLinksMobile {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #FFE81C;
  color: white;
  z-index: 20;
  padding: 0;
  border-bottom: 1px solid white;
  font-family: 'Quicksand', sans-serif;
  font-weight: 700;
}
#TopLinksMobile .navbar {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  #TopLinksMobile {
    display: none !important;
  }
}
#TopLinksMobile #NavigationToggleText {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 14px 63px;
  text-align: right;
}
#TopLinksMobile .navbar-toggle .icon-bar {
  background: white;
}
#TopLinksMobile .row {
  padding-top: 10px;
}
#TopLinksMobile .input-group {
  margin-bottom: 0;
}
#MobileHomeLink {
  position: fixed;
  top: 10px;
  z-index: 10;
  background: transparent none repeat scroll 0 0;
  color: white;
  left: 10px;
  border-radius: 50%;
  border: 2px solid white;
}
/*#endregion topLinksMobile*/
.header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding-top: 80px;
}
@media (min-width: 768px) {
  .header {
    padding-top: 30px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
}
.header__cloud {
  position: absolute;
  -webkit-transform: translate(-40%, 100%);
          transform: translate(-40%, 100%);
}
.header a {
  color: #555960;
  font-family: 'Fredoka One', cursive;
  font-size: 20px;
}
@media (min-width: 992px) {
  .header a {
    font-size: 40px;
  }
}
.header h3 {
  color: #555960;
  font-size: 18px;
}
@media (min-width: 992px) {
  .header h3 {
    font-size: 34px;
  }
}
.header__call-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  text-align: center;
  -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
          order: 1;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media (min-width: 992px) {
  .header__call-container {
    width: 32%;
  }
}
@media (max-width: 767px) {
  .header__logo {
    -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
            order: 0;
  }
}
@media (min-width: 768px) {
  .header__logo {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
  }
}
@media (min-width: 992px) {
  .header__logo {
    width: 32%;
  }
}
.header__email-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  text-align: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
          order: 2;
}
@media (min-width: 992px) {
  .header__email-container {
    width: 32%;
  }
}
.header__email-container a {
  font-size: 20px;
}
@media (min-width: 992px) {
  .header__email-container a {
    font-size: 30px;
  }
}
.header__phone-icon {
  width: 25%;
  max-width: 75px !important;
}
.header__email-icon {
  width: 25%;
  max-width: 80px !important;
}
.toplinks {
  position: relative;
  padding: 0 15px;
}
.toplinks__wrapper {
  background-image: url(https://bouncycastlenetwork-res.cloudinary.com/image/upload/v1638982722/Sites/Bubble%20N%20Bounce/toplinks-bg.png);
  background-position-y: top;
  background-repeat: repeat-x;
  padding: 30px 0;
}
.toplinks__container {
  padding-left: 0;
  padding-right: 0;
}
.toplinks__nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-bottom: 0;
  padding-left: 0;
}
.toplinks__li {
  position: relative;
  list-style: none;
  text-align: center;
}
.toplinks__li .dropdown-toggle:focus {
  text-decoration: none;
}
.toplinks__link {
  position: relative;
  display: block;
  padding: 15px 20px;
  color: #555960;
  font-size: 26px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  font-family: 'Fredoka One', cursive;
}
.toplinks__link:hover,
.toplinks__link.active,
.toplinks__link:focus {
  color: #555960;
  background: #FFE81C;
  border-radius: 5px;
}
.toplinks__dropdown {
  margin: 0;
  border: none;
  border-radius: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
  text-align: center;
  background-color: #FEFFD1 !important;
  -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.25);
          box-shadow: 0 0 3px rgba(0, 0, 0, 0.25);
}
.toplinks__dropdown-li {
  display: block;
}
.toplinks__dropdown-link {
  position: relative;
  padding: 5px 10px !important;
  font-size: 1.2rem;
  color: #555960 !important;
  font-family: 'Fredoka One', cursive;
}
.toplinks__dropdown-link:hover,
.toplinks__dropdown-link:focus,
.toplinks__dropdown-link.active {
  background-color: #FFE81C !important;
}
.banner {
  padding: 30px 0;
}
.banner #CarouselContainer {
  padding-top: 46.25%;
  overflow: hidden;
}
.banner #CarouselContainer .carousel-inner {
  position: absolute;
  top: 0;
  height: 100%;
}
.banner #CarouselContainer .carousel-inner .item {
  width: 100%;
}
.banner #CarouselContainer .carousel-inner .item a img,
.banner #CarouselContainer .carousel-inner .item img {
  width: 100%;
}
.banner #CarouselContainer {
  border-radius: 25px;
}
.book-online {
  background: #FFE81C;
}
.book-online__title {
  color: #555960;
  font-size: 2rem;
  text-align: center;
  font-family: 'Fredoka One', cursive;
}
@media (min-width: 768px) {
  .book-online__title {
    text-align: left;
  }
}
.book-online__inner {
  padding: 30px 10px;
}
@media (max-width: 767px) {
  .book-online__widgets {
    text-align: center;
  }
}
@media (min-width: 768px) {
  .book-online__widgets > div {
    display: inline-block;
  }
}
.book-online #PostcodeSearchLeft select,
.book-online #PostcodeSearchLeft input,
.book-online .SearchDate,
.book-online .SearchCategory,
.book-online .SearchButton {
  border: none;
  border-radius: 4px;
  width: 224px;
  height: 50px;
  margin: 0 5px;
  display: inline-block;
  vertical-align: top;
  padding-left: 10px;
}
@media (max-width: 767px) {
  .book-online #PostcodeSearchLeft select,
  .book-online #PostcodeSearchLeft input,
  .book-online .SearchDate,
  .book-online .SearchCategory,
  .book-online .SearchButton {
    display: block;
    margin: 10px auto;
  }
}
@media (min-width: 768px) {
  .book-online #PostcodeSearchLeft select,
  .book-online #PostcodeSearchLeft input,
  .book-online .SearchDate,
  .book-online .SearchCategory,
  .book-online .SearchButton {
    width: 156px;
  }
}
@media (min-width: 992px) {
  .book-online #PostcodeSearchLeft select,
  .book-online #PostcodeSearchLeft input,
  .book-online .SearchDate,
  .book-online .SearchCategory,
  .book-online .SearchButton {
    width: 224px;
  }
}
.book-online .SearchButton {
  background: #FFF;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #555960;
  text-transform: uppercase;
  width: 140px;
  font-family: 'Fredoka One', cursive;
}
.book-online .SearchButton:hover {
  background: #A7ADBB;
  color: #FFF;
}
@media (min-width: 768px) {
  .book-online .SearchButton {
    width: 98px;
  }
}
@media (min-width: 992px) {
  .book-online .SearchButton {
    width: 140px;
  }
}
.featured-categories {
  margin: 30px 0;
}
.featured-categories__image-panel {
  position: relative;
  padding-top: 86%;
}
.featured-categories__image {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 5px 5px 0 0;
}
.featured-categories__title {
  color: #555960;
  background: #FFE81C;
  font-family: 'Fredoka One', cursive;
  font-size: 25px;
  text-align: center;
  border-radius: 0 0 5px 5px;
  padding: 10px 0;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: -webkit-box;
  display: flex;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.featured-categories__inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.featured-categories__link {
  display: inline-block;
  width: 49%;
  padding: 15px;
}
@media (min-width: 768px) {
  .featured-categories__link {
    width: 19%;
    padding: 0;
    margin: 10px 0;
  }
}
.main-content {
  background: #FEFFD1;
}
.main-content .columns {
  background: #FFF;
  border-radius: 15px;
  padding: 40px;
  margin: 30px 0;
}
.about {
  padding: 60px;
  background: #FFF;
}
.about__title {
  color: #A7ADBB;
  text-align: center;
  font-family: 'Fredoka One', cursive;
  margin-bottom: 40px;
}
.about__title h2 {
  color: #A7ADBB;
  font-size: 50px;
}
.about__title h2 a {
  color: #A7ADBB;
}
.about__title p {
  font-size: 30px;
}
.about__title p a {
  color: #A7ADBB;
}
.about__contact {
  color: #FFF;
  background: #A7ADBB;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  border-radius: 10px;
  text-align: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 20px 40px;
  font-family: 'Fredoka One', cursive;
}
.about__contact h2 {
  font-size: 46px;
}
.about__contact h3 {
  font-size: 30px;
}
.testimonials {
  background: #AEEEED;
  padding: 40px 0 60px;
}
.testimonials h2 {
  text-align: center;
  color: #FFF;
  font-size: 56px;
  margin-bottom: 50px;
}
.testimonials .RotatorTestimonial {
  background: #FFF;
  color: #737373;
  font-family: 'Quicksand', sans-serif;
  font-weight: 600;
  font-size: 22px;
  text-align: center;
  background-image: url(https://bouncycastlenetwork-res.cloudinary.com/image/upload/v1637610004/Sites/Bubble%20N%20Bounce/testimonials-bg.png);
  background-position-y: bottom;
  background-size: 100%;
  background-repeat: no-repeat;
  border-radius: 3px;
  position: relative;
  padding: 40px 10px;
}
@media (min-width: 992px) {
  .testimonials .RotatorTestimonial {
    margin: 0 20px;
    padding: 40px;
  }
}
.testimonials .RotatorTestimonial:before {
  content: url(https://bouncycastlenetwork-res.cloudinary.com/image/upload/v1637608237/Sites/Bubble%20N%20Bounce/speechmarks.png);
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transform: translate(24%, 12%);
          transform: translate(24%, 12%);
}
.testimonials .RotatorTestimonialContent {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse;
  margin: auto 0;
}
.testimonials .RotatorTestimonialRating {
  width: 50%;
  margin: auto;
}
.testimonials #RotatorTestimonialsHeader {
  font-size: 1rem;
  color: #555960;
}
.testimonials #RotatorTestimonialsHeader a {
  color: #A7ADBB;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.testimonials #RotatorTestimonialsHeader a:hover {
  color: #555960;
}
.testimonials__inner {
  position: relative;
}
.testimonials time {
  display: none;
}
.testimonials a[itemprop="author"] {
  color: #FFE81C;
}
.testimonials p[itemprop="reviewBody"] {
  z-index: 1000;
}
.testimonials__button {
  position: absolute;
  z-index: 1;
  top: 50%;
  background-color: transparent;
  border: none;
  display: none;
}
.category-links {
  background: #A7ADBB;
  padding: 20px;
  border-radius: 5px;
  -webkit-filter: drop-shadow(0 0 0.4rem #999);
          filter: drop-shadow(0 0 0.4rem #999);
}
.category-links__link {
  position: relative;
  display: block;
  margin-bottom: 15px;
  padding: 10px 15px;
  text-align: center;
  font-size: 25px;
  background: #FFF;
  color: #A7ADBB;
  font-family: 'Fredoka One', cursive;
  border-radius: 5px;
}
.category-links__link:hover,
.category-links__link.active {
  color: #5E6472;
}
@media (max-width: 420px) {
  .page-listings > div {
    width: 100%;
  }
}
.castlePanel {
  width: 100%;
  margin: 0 0 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  border: none;
  overflow: hidden;
  padding: 10px 10px 20px;
  border-radius: 10px;
  -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
          box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
  -webkit-transition: -webkit-box-shadow 0.3s;
  transition: -webkit-box-shadow 0.3s;
  transition: box-shadow 0.3s;
  transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
  background: #FFE81C;
  color: #555960;
}
.castlePanel:hover {
  -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
          box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
}
.castlePanel .SingleImagePanel {
  height: auto;
  background-color: transparent;
  padding-top: 0;
  position: relative;
}
.castlePanel .SingleImagePanel:before {
  content: "";
  display: block;
  padding-top: 100%;
  width: 100%;
}
.castlePanel .SingleImagePanel img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 5px;
}
.castlePanel .castleTitlePanel {
  padding: 0 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 10px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.castlePanel .castleTitlePanel a {
  font-size: 40px;
  font-family: 'Fredoka One', cursive;
  color: #555960;
}
.castlePanel .castlePriceDefault,
.castlePanel .castlePriceDiscounted {
  padding: 0 5px;
  margin-top: 10px;
  font-size: 36px;
  font-family: 'Fredoka One', cursive;
}
.castlePanel .castlePriceDefault span.oldprice,
.castlePanel .castlePriceDiscounted span.oldprice {
  font-size: 0.8rem;
}
.castlePanel .castlePriceDefault span.newprice,
.castlePanel .castlePriceDiscounted span.newprice {
  font-size: 1rem;
}
.castlePanel .castleCheckBook {
  height: auto;
  -webkit-box-ordinal-group: 5;
      -ms-flex-order: 4;
          order: 4;
  padding: 10px 5px;
  margin-top: 10px;
  background: #FFF;
  font-family: 'Fredoka One', cursive;
  color: #555960;
  font-size: 25px;
  border-radius: 5px;
}
.DetailsTitle h1,
.DetailsTitle h2,
.DetailsMainImage {
  background-color: #AEEEED;
  font-size: 2rem;
}
.DetailsTitle h1,
.DetailsTitle h2 {
  padding: 10px;
}
@media (max-width: 767px) {
  a.BackToCategory {
    -webkit-transform: translateY(30px);
            transform: translateY(30px);
    margin-bottom: 0;
  }
}
.DetailsLeft {
  background-image: none;
  padding-bottom: 0;
}
.BookButtons {
  text-align: center;
}
.BookButtons .BookButton,
.BookButtons .CheckButton {
  width: 180px;
  margin-left: auto;
  float: none;
  margin: 0 10px 10px;
}
.upper-footer {
  background: -webkit-gradient(linear, left top, left bottom, from(#A7ADBB), to(#9299A9));
  background: linear-gradient(to bottom, #A7ADBB, #9299A9);
  padding: 30px 0;
  color: #FFF;
  text-align: center;
}
.upper-footer h3 {
  font-size: 26px;
}
.upper-footer h2 {
  font-size: 46px;
}
.footer {
  background: #A7ADBB;
  padding: 30px 0;
  color: #fff;
  font-size: 18px;
}
.footer a,
.footer p {
  color: #fff;
}
.footer a:hover,
.footer p:hover {
  color: #fff;
}
@media (max-width: 767px) {
  .footer__col {
    text-align: center;
  }
}
@media (min-width: 992px) {
  .footer__flex-row-md {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}
.footer__bottom-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
@media (min-width: 992px) {
  .footer__bottom-inner {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
}
.footer__bottom-mid,
.footer__bottom-left,
.footer__bottom-right {
  width: auto;
  text-align: center;
}
.footer__bottom-mid {
  -webkit-box-ordinal-group: 1;
      -ms-flex-order: 0;
          order: 0;
}
@media (min-width: 992px) {
  .footer__bottom-mid {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
    width: 50%;
  }
}
.footer__bottom-left {
  -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
          order: 1;
}
@media (min-width: 992px) {
  .footer__bottom-left {
    -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
            order: 0;
    width: 25%;
  }
}
.footer__bottom-right {
  -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
          order: 2;
}
@media (min-width: 992px) {
  .footer__bottom-right {
    width: 25%;
  }
}
.footer__links {
  text-align: center;
  margin-bottom: 10px;
}
.footer__link {
  margin: 0 5px;
}
/*#region Animations*/
.wow {
  visibility: hidden;
}
.fadeInUp2 {
  -webkit-animation-name: fadeInUp2;
          animation-name: fadeInUp2;
}
@-webkit-keyframes fadeInUp2 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100px, 0);
            transform: translate3d(0, 100px, 0);
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeInUp2 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100px, 0);
            transform: translate3d(0, 100px, 0);
  }
  100% {
    opacity: 1;
  }
}
/*#endregion Animations*/
.about {
  padding: 60px;
  background: #FFF;
}

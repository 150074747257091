//@import "D:\Sites\bubble_n_bounce_ltd_21_10_28\Autobopr\Assets\functions.less";
//@import (inline) "D:/Sites/bubble_n_bounce_ltd_21_10_28/Autobopr/Assets/utilities.css";
@import "../Autobopr/Assets/Functions.less";
@import (inline) "../Autobopr/Assets/Utilities.css";
@xxs: ~"(max-width: 400px)";
@mobile: ~"(max-width: 767px)";
@tablet: ~"(min-width: 768px)";
@tablet-only: ~"(min-width: 768px) and (max-width: 1229px)";
@desktop: ~"(min-width: 1330px)";
@mobileNavColor: #FFE81C;
@castleDetailsColor: #AEEEED;
@blue: #AEEEED;
@grey: #A7ADBB;
@yellow: #FFE81C;
/*#region fonts*/
.BodyFont {
	font-family: 'Quicksand', sans-serif;
	font-weight: 700;
}

.Quicksand {
	font-family: 'Quicksand', sans-serif;
}

.FredokaOne {
	font-family: 'Fredoka One', cursive;
}

html {
	font-size: 16px;
}

body {
	.BodyFont;
}

.nicefont {
}

.textContent {
	@color: #737373;
	color: @color;
	font-size: 1rem;

	@media @xxs {
		a {
			// for long links in chrome
			word-break: break-word;
		}
	}

	@media @mobile {
		text-align: center;
	}

	h1 {
		margin-top: 0;
		.FredokaOne;
		color: @grey;
	}

	p {
		margin-bottom: 20px;
	}


	a {
		position: relative;
		padding: 0 3px;
		margin: 0 1px;
		display: inline-block;
		color: #5E6472;
		transition: color 0.3s;

		&:hover {
			color: #5E6472;

			&:before {
				transform: scaleY(1);
			}
		}

		&:before {
			content: "";
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: #5E6472;
			transition: transform 0.3s;
			z-index: -1;
			transform-origin: center bottom;
			transform: scaleY(0.1);
		}
	}
}

h2, h3, h4 {
	.FredokaOne;
}
/*#endregion fonts*/
form {
	position: relative;
	max-width: 100vw;
}

.outer-wrapper {
	max-width: 100vw;
	overflow-x: hidden;
	position: relative;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	background: #AEEEED;
	// any non-homepage page
	&--alt {
		.main-content {
			flex-grow: 1;
		}
	}
	// homepage only
	&--home {
		.main-content {
			@media @sm {
				margin-top: -85px;
				padding-top: 70px;
			}
		}
	}
	// category page only
	&--category {
	}
	// contact page only
	&--contact {
	}
}

.container {
	position: relative;
	width: 100%;

	@media @mobile {
	}

	@media @tablet {
	}

	@media @desktop {
		width: 1300px;
	}
}
/*#region topLinksMobile*/
#TopLinksMobile {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	background: @mobileNavColor;
	color: white;
	z-index: 20;
	padding: 0;
	border-bottom: 1px solid white;
	.BodyFont();

	.navbar {
		margin-bottom: 0;
	}

	@media @tablet {
		display: none !important;
	}

	#NavigationToggleText {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		padding: 14px 63px;
		text-align: right;
	}

	.navbar-toggle .icon-bar {
		background: white;
	}

	.row {
		padding-top: 10px;
	}

	.input-group {
		margin-bottom: 0;
	}
}

#MobileHomeLink {
	position: fixed;
	top: 10px;
	z-index: 10;
	background: transparent none repeat scroll 0 0;
	color: white;
	left: 10px;
	border-radius: 50%;
	border: 2px solid white;
}
/*#endregion topLinksMobile*/
.header {
	display: flex;
	align-items: center;
	flex-direction: column;
	padding-top: 80px;

	@media @sm {
		padding-top: 30px;
		flex-direction: row;
		justify-content: space-between;
	}

	&__cloud {
		position: absolute;
		transform: translate(-40%,100%);
	}

	a {
		color: #555960;
		.FredokaOne;
		font-size: 20px;

		@media @md {
			font-size: 40px;
		}
	}

	h3 {
		color: #555960;
		font-size: 18px;

		@media @md {
			font-size: 34px;
		}
	}

	&__call-container {
		display: flex;
		flex-direction: column;
		text-align: center;
		order: 1;
		align-items: center;

		@media @md {
			width: 32%;
		}
	}

	&__logo {
		@media @xs {
			order: 0;
		}

		@media @sm {
			order: 1;
		}

		@media @md {
			width: 32%;
		}
	}

	&__email-container {
		display: flex;
		flex-direction: column;
		text-align: center;
		align-items: center;
		order: 2;

		@media @md {
			width: 32%;
		}

		a {
			font-size: 20px;

			@media @md {
				font-size: 30px;
			}
		}
	}

	&__phone {
		&-icon {
			width: 25%;
			max-width: 75px !important;
		}
	}

	&__email {
		&-icon {
			width: 25%;
			max-width: 80px !important;
		}
	}
}

.toplinks {
	position: relative;
	padding: 0 15px;

	&__wrapper {
		background-image: url(https://bouncycastlenetwork-res.cloudinary.com/image/upload/v1638982722/Sites/Bubble%20N%20Bounce/toplinks-bg.png);
		background-position-y: top;
		background-repeat: repeat-x;
		padding: 30px 0;
	}

	&__container {
		padding-left: 0;
		padding-right: 0;
	}

	&__nav {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		margin-bottom: 0;
		padding-left: 0;
	}

	&__li {
		position: relative;
		list-style: none;
		text-align: center;

		.dropdown-toggle:focus {
			text-decoration: none;
		}
	}

	&__link {
		position: relative;
		display: block;
		padding: 15px 20px;
		color: #555960;
		font-size: 26px;
		transition: all 0.3s;
		.FredokaOne;

		&:hover, &.active, &:focus {
			color: #555960;
			background: #FFE81C;
			border-radius: 5px;
		}
	}

	&__dropdown {
		margin: 0;
		border: none;
		border-radius: 0;
		box-shadow: none;
		text-align: center;
		background-color: #FEFFD1 !important;
		box-shadow: 0 0 3px rgba(0,0,0,0.25);
	}

	&__dropdown-li {
		display: block;
	}

	&__dropdown-link {
		position: relative;
		padding: 5px 10px !important;
		font-size: 1.2rem;
		color: #555960 !important;
		.FredokaOne;

		&:hover, &:focus, &.active {
			background-color: #FFE81C !important;
		}
	}
}

.banner {
	.BannerPanelSet(46.25%);
	padding: 30px 0;

	#CarouselContainer {
		border-radius: 25px;
	}
}

.book-online {
	background: @yellow;

	&__title {
		color: #555960;
		font-size: 2rem;
		text-align: center;
		.FredokaOne;

		@media @sm {
			text-align: left;
		}
	}

	&__inner {
		padding: 30px 10px;
	}

	&__widgets {
		@media @mobile {
			text-align: center;
		}

		& > div {
			@media @sm {
				display: inline-block;
			}
		}
	}

	#PostcodeSearchLeft select, #PostcodeSearchLeft input,
	.SearchDate, .SearchCategory, .SearchButton {
		border: none;
		border-radius: 4px;
		width: 224px;
		height: 50px;
		margin: 0 5px;
		display: inline-block;
		vertical-align: top;
		padding-left: 10px;

		@media @mobile {
			display: block;
			margin: 10px auto;
		}

		@media @sm {
			width: 156px;
		}

		@media @md {
			width: 224px;
		}
	}

	.SearchButton {
		background: #FFF;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		color: #555960;
		text-transform: uppercase;
		width: 140px;
		.FredokaOne;
		&:hover {
			background: @grey;
			color: #FFF;
		}

		@media @sm {
			width: 98px;
		}

		@media @md {
			width: 140px;
		}
	}
}


.featured-categories {
	margin: 30px 0;
	// this makes a box that will keep its aspect ratio as the box size decreases
	&__image-panel {
		position: relative;
		padding-top: 86%; //get from image h/w*100
	}
	// then we can set the image into the box so that it'll never be any bigger than the box
	&__image {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		border-radius: 5px 5px 0 0;
	}

	&__title {
		color: #555960;
		background: @yellow;
		.FredokaOne;
		font-size: 25px;
		text-align: center;
		border-radius: 0 0 5px 5px;
		padding: 10px 0;
		.FlexCenter;
	}

	&__inner {
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		flex-wrap: wrap;

		@media @sm {
		}
	}

	&__link {
		display: inline-block;
		width: 49%;
		padding: 15px;

		@media @sm {
			width: 19%;
			padding: 0;
			margin: 10px 0;
		}
	}

	&__description {
	}

	&__button {
	}
}

.main-content {
	background: #FEFFD1;

	.columns {
		background: #FFF;
		border-radius: 15px;
		padding: 40px;
		margin: 30px 0;
	}
}

.about {
	padding: 60px;
	background: #FFF;

	&__title {
		color: @grey;
		text-align: center;
		.FredokaOne;
		margin-bottom: 40px;

		h2 {
			color: @grey;
			font-size: 50px;

			a {
				color: @grey;
			}
		}

		p {
			font-size: 30px;

			a {
				color: @grey;
			}
		}
	}

	&__contact {
		color: #FFF;
		background: @grey;
		display: flex;
		flex-direction: column;
		border-radius: 10px;
		text-align: center;
		width: fit-content;
		padding: 20px 40px;
		.FredokaOne;

		h2 {
			font-size: 46px;
		}

		h3 {
			font-size: 30px;
		}
	}
}

.testimonials {
	background: @blue;
	padding: 40px 0 60px;

	h2 {
		text-align: center;
		color: #FFF;
		font-size: 56px;
		margin-bottom: 50px;
	}

	.RotatorTestimonial {
		background: #FFF;
		color: #737373;
		.Quicksand;
		font-weight: 600;
		font-size: 22px;
		text-align: center;
		background-image: url(https://bouncycastlenetwork-res.cloudinary.com/image/upload/v1637610004/Sites/Bubble%20N%20Bounce/testimonials-bg.png);
		background-position-y: bottom;
		background-size: 100%;
		background-repeat: no-repeat;
		border-radius: 3px;
		position: relative;
		padding: 40px 10px;

		@media @md {
			margin: 0 20px;
			padding: 40px;
		}

		&:before {
			content: url(https://bouncycastlenetwork-res.cloudinary.com/image/upload/v1637608237/Sites/Bubble%20N%20Bounce/speechmarks.png);
			position: absolute;
			top: 0;
			left: 0;
			transform: translate(24%, 12%);
		}
	}

	.RotatorTestimonialContent {
		display: flex;
		flex-direction: column-reverse;
		margin: auto 0;
	}

	.RotatorTestimonialRating {
		width: 50%;
		margin: auto;
	}

	#RotatorTestimonialsHeader {
		font-size: 1rem;
		color: #555960;

		a {
			color: @grey;
			transition: all 0.5s;

			&:hover {
				color: #555960;
			}
		}
	}

	&__inner {
		position: relative;
	}

	time {
		display: none;
	}

	a[itemprop="author"] {
		color: @yellow;
	}

	p[itemprop="reviewBody"] {
		z-index: 1000;
	}
	// only needed if buttons added from the .js
	&__button {
		position: absolute;
		z-index: 1;
		top: 50%;
		background-color: transparent;
		border: none;
		display: none;
	}
}



.category-links {
	background: @grey;
	padding: 20px;
	border-radius: 5px;
	filter: drop-shadow(0 0 0.4rem #999);

	&__link {
		position: relative;
		display: block;
		margin-bottom: 15px;
		padding: 10px 15px;
		text-align: center;
		font-size: 25px;
		background: #FFF;
		color: @grey;
		.FredokaOne;
		border-radius: 5px;

		&:hover, &.active {
			color: #5E6472;
		}
	}
}

.page-listings {

	& > div {
		@media (max-width:420px) {
			width: 100%;
		}
	}
}

.castlePanel {
	width: 100%;
	margin: 0 0 30px;
	display: flex;
	flex-direction: column;
	border: none;
	overflow: hidden;
	padding: 10px 10px 20px;
	border-radius: 10px;
	box-shadow: 0 3px 5px rgba(0,0,0,0.3);
	transition: box-shadow 0.3s;
	background: #FFE81C;
	color: #555960;

	&:hover {
		box-shadow: 0 2px 2px rgba(0,0,0,0.1);
	}

	.SingleImagePanel {
		height: auto;
		background-color: transparent;
		padding-top: 0;
		position: relative;

		&:before {
			content: "";
			display: block;
			padding-top: 100%;
			width: 100%;
		}

		img {
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			width: 100%;
			height: 100%;
			-o-object-fit: cover;
			object-fit: cover;
			border-radius: 5px;
		}
	}

	.castleTitlePanel {
		padding: 0 5px;
		display: flex;
		margin-top: 10px;
		justify-content: center;
		align-items: center;

		a {
			font-size: 40px;
			.FredokaOne;
			color: #555960;
		}
	}

	shortProductDescription {
	}

	.castlePriceDefault, .castlePriceDiscounted {
		padding: 0 5px;
		margin-top: 10px;
		font-size: 36px;
		.FredokaOne;

		span.oldprice {
			font-size: 0.8rem;
		}

		span.newprice {
			font-size: 1rem;
		}
	}

	span.oldprice, span.newprice {
	}

	.castleCheckBook {
		height: auto;
		order: 4;
		padding: 10px 5px;
		margin-top: 10px;
		//text-transform: uppercase;
		background: #FFF;
		.FredokaOne;
		color: #555960;
		font-size: 25px;
		border-radius: 5px;
	}
}

.DetailsTitle h1, .DetailsTitle h2, .DetailsMainImage {
	background-color: @castleDetailsColor;
	font-size: 2rem;
}

.DetailsTitle h1, .DetailsTitle h2 {
	padding: 10px;
}

a.BackToCategory {
	@media @mobile {
		transform: translateY(30px);
		margin-bottom: 0;
	}

	&:hover{

	}
}

.DetailsLeft {
	background-image: none;
	padding-bottom: 0;
}

.BookButtons {
	text-align: center;

	.BookButton, .CheckButton {
		width: 180px;
		margin-left: auto;
		float: none;
		margin: 0 10px 10px;
	}
}

.upper-footer {
	background: linear-gradient(to bottom, @grey, #9299A9);
	padding: 30px 0;
	color: #FFF;
	text-align: center;

	h3 {
		font-size: 26px;
	}

	h2 {
		font-size: 46px;
	}
}

.footer {
	background: @grey;
	padding: 30px 0;
	color: #fff;
	font-size: 18px;

	a, p {
		color: #fff;

		&:hover {
			color: #fff;
		}
	}

	&__col {
		@media @mobile {
			text-align: center;
		}
	}

	&__flex-row-md {
		@media @md {
			display: flex;
			align-items: center;
		}
	}

	&__bottom {
		&-inner {
			display: flex;
			flex-direction: column;

			@media @md {
				flex-direction: row;
				flex-wrap: wrap;
				justify-content: space-between;
			}
		}

		&-mid, &-left, &-right {
			width: auto;
			text-align: center;
		}

		&-mid {
			order: 0;

			@media @md {
				order: 1;
				width: 50%;
			}
		}

		&-left {
			order: 1;

			@media @md {
				order: 0;
				width: 25%;
			}
		}

		&-right {
			order: 2;

			@media @md {
				width: 25%;
			}
		}
	}

	&__links {
		text-align: center;
		margin-bottom: 10px;
	}

	&__link {
		margin: 0 5px;
	}
}
/*#region Animations*/
.wow {
	visibility: hidden;
}

.fadeInUp2 {
	animation-name: fadeInUp2;
}

@keyframes fadeInUp2 {
	0% {
		opacity: 0;
		transform: translate3d(0, 100px,0);
	}

	100% {
		opacity: 1;
	}
}
/*#endregion Animations*/

.about {
    padding: 60px;
    background: #FFF;
}
